<template>
    <div class="view-trip-list" v-if="translates && translates[langUrl]">
        <nav class="navigation" v-if=" translates.navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="tripList">{{ translates.navigation.button_route_trips[lang] }}</div>
        </nav>
        <h1 class="view-trip-list__title" v-if="translates[langUrl]">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div class="view-trip-list__content">
            <div v-if="isDesktop">
                <DesktopFilters :default-filter="defaultFilter" @apply="applyFilters" />
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in trips">
                        <DesktopPreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['favorites']"
                            :bloggers-list="bloggersList"
                            @favoriteAdd="favoriteAdd"
                            @favoriteDelete="favoriteDelete"
                            @setStatus="setStatus"
                        />
                    </template>
                </div>
            </div>
            <div v-else>
                <Filters :default-filter="defaultFilter" :tripsCount="tripsCount" @apply="applyFilters" />
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in trips">
                        <PreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['favorites', 'pdf']"
                            :bloggers-list="bloggersList"
                            @favoriteAdd="favoriteAdd"
                            @favoriteDelete="favoriteDelete"
                            @setStatus="setStatus" />
                    </template>
                </div>
            </div>
        </div>
        <v-pagination
            v-if="tripsCount > 10"
            v-model="page"
            :length="pageCount()"
            circle
            @input="getTrips"
        />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import PreviewCard from '../../components/common/trip/PreviewCard.vue';
    import DesktopPreviewCard from '../../components/desktop/trip/PreviewCard.vue'
    import DesktopFilters from '../../components/desktop/trip/Filters.vue'
    import Filters from '../../components/mobile/trip/Filters.vue'
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';

    export default {
        name: 'Trips',
        // metaInfo: {
        //     title: 'Туры'
        // },
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle[this.lang]
            }
        },
        components: {
            PreviewCard,
            DesktopPreviewCard,
            DesktopFilters,
            Filters
        },
        data: () => ({
            isDesktop: false,
            filter: {},
            defaultFilter: {},
            page: 1,
            bloggersList: false,
            langUrl: '/trips'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities,
                tripsCount: state => state.entitiesCount,
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        // beforeRouteEnter(from, to, next) {
        //     ()
        // },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            // if(!this.user._id) {
            //     try {
            //         await store.dispatch('auth/fetch');
            //     } catch(e) {}
            // }
            if (this.$route.name === 'bloggers-trip-list') {
                this.bloggersList = true;
            }
            if(this.$route.params.filter) {
                this.applyFilters({ filter: this.$route.params.filter, sorting: this.sorting, page: this.page });
                this.defaultFilter = this.$route.params.filter;
            } else if(this.$route.query) {
                const filter = this.$route.query;
                if(filter.tripType) {
                    filter.tripType = JSON.parse(filter.tripType);
                }
                this.applyFilters({ filter, search: this.search, sorting: this.sorting, page: this.page });
                this.defaultFilter = filter;
            } else {
                await this.getTrips();
            }
        },
        methods: {
            pageCount() {
                return Math.ceil(this.tripsCount / 10)
            },
            async tripList () {
                await this.$router.push({ name: 'trip-list' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting, page: this.page });
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async applyFilters({ filter, sorting }) {
                this.filter = filter;
                this.sorting = sorting;
                this.page = 1;
                await this.getTrips();
            },
            async favoriteAdd({ id }) {
                if (!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                    this.$root.$once('authorized', async () => {
                        await store.dispatch('trips/favoriteAdd', { id });
                        await store.dispatch('auth/fetch');
                        await this.getTrips();
                    });
                } else {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                }
            },
            async favoriteDelete({ id }) {
                if (!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                    this.$root.$once('authorized', async () => {
                        await store.dispatch('trips/favoriteAdd', { id });
                        await store.dispatch('auth/fetch');
                        await this.getTrips();
                    });
                } else {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            }
        }
    }
</script>

<style lang="scss">
.link {
    cursor: pointer;
    margin-right: 3px;
}
.view-trip-list {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    @media all and (min-width: 768px) {
        padding: 0 32px;
    }
    @media all and (min-width:1464px) {
        padding: 0;
    }
    &__navigation{
        margin: 13px 24px 0;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #44414D;
        display: flex;
        flex-direction: row;
        @media all and (min-width:768px) {
            margin: 13px 0 0;
        }
    }
    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #3A4256;
        margin: 32px 24px 12px;
        @media all and (min-width:768px) {
        font-weight: bold;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        margin: 40px 0 36px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
    }
    &__preview {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media all and (min-width:768px) {
            flex-direction: column;
            justify-content: space-between;
            // padding: 0 32px;
        }
    }
}

</style>
