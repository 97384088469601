<template>
    <div class="view-trip-filter__pagefilters" v-if="translates && translates[langUrl]">
        <FilterSelect
            type="map"
            :value="filter.directions"
            :items="[{ code: null, title: `${ translates[langUrl].countries_notImportant[lang] }` }, ...countries]"
            item-text="title"
            item-value="code"
            class="view-trip-filter__pagefilters__select"
            @change="updateFilterPlace"
        />
        <!-- <CustomTextField
            :value="filter.search"
            class="desktop-view-trip-filters__pagefilters__text-field"
            @input="updateFilterSearch"
        /> -->
        <!-- <FilterSelect
            :label="translates[langUrl].label_price[lang]"
            :value="filter.price"
            :items="selectPrice"
            item-text="label"
            item-value="value"
            @change="updateFilterPrice"
        /> -->
        <!-- <FilterSelect
            :label="translates[langUrl].label_tripType[lang]"
            :value="filter.tripType"
            :items="types"
            item-text="title"
            item-value="code"
            @change="updateFilterType"
        /> -->
        <!-- <FilterSelect
            label="Возрастной диапазон"
            :value="filter.groupAge"
            :items="selectGroupAge"
            item-text="label"
            item-value="value"
            @change="updateFilterGroupAge"
        />
        <FilterSelect
            :label="translates[langUrl].label_groupSize[lang]"
            :value="filter.groupSize"
            :items="selectGroupSize"
            item-text="label"
            item-value="value"
            @change="updateFilterGroupSize"
        /> -->
        <RangeDatePicker
            :value="filter.dates"
            type="month"
            :label="translates[langUrl].label_dates[lang]"
            @change="updateFilterDates"
        />
        <!-- <div class="view-trip-filter__button view-trip-filter__button__clear" @click="clearFilters">Очистить фильтры</div> -->
        <div class="view-trip-filter__link">
            <a class="view-trip-filter__link__clear" @click="clearFilters">{{ translates[langUrl].button_clearFilters[lang] }}</a>
        </div>
        <!-- <div class="view-trip-filter__buttons">
            <div class="button view-trip-filter__button">
                <img src="../../../assets/Users.svg" class="view-trip-filter__button__icon" />
                Едут Друзья
            </div>
            <div class="button view-trip-filter__button">
                <img src="../../../assets/Filter.svg" class="view-trip-filter__button__icon" />
                Все фильтры
            </div>
        </div> -->
        <div class="view-trip-filter__pagefilters__results">
            <img src="../../../assets/Website.svg" class="view-trip-filter__pagefilters__results__icon" />
            <div class="view-trip-filter__pagefilters__results__text">{{ translates[langUrl].resultCount[lang] }} {{ tripsCount }}</div>
        </div>
        <div class="view-trip-filter__pagefilters__sort">
            <div class="view-trip-filter__pagefilters__sort__static">{{ translates[langUrl].label_sorting[lang] }}</div>
            <SortingSelect
                :value="sorting" 
                :items="selectSorting"
                item-text="label"
                item-value="value"
                @change="updateSorting"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import FilterSelect from '../../common/FilterSelect.vue';
    import RangeDatePicker from '../../common/RangeDatePicker.vue'; 
    import SortingSelect from '../../common/SortingSelect.vue'
    import BaseCombobox from '../../common/BaseCombobox.vue'
    import BaseSelect from '../../common/BaseSelect.vue'
    import CustomTextField from '../../common/CustomTextField.vue'

    export default {
        name: 'Filters',
        components: {
            BaseSelect,
            FilterSelect,
            RangeDatePicker,
            SortingSelect,
            BaseCombobox,
            CustomTextField
        },
        props: {
            tripsCount: Number,
            defaultFilter: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            filter: { 
                search: '',
                dates: [],
                directions: null,
                tripType: null,
                groupAge: null,
                groupSize: null,
                price: null
            },
            sorting: { createdAt: -1 },
            selectGroupAge: [
                { label: 'не важно', value: null }, 
                { label: '18+', value: '18' }, 
                { label: '30+', value: '30' },
                { label: '40+', value: '40' },
                { label: '50+', value: '50' },
            ],
            langUrl: '/trips_filters'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('dictionaries', ['types', 'countries', 'cities']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            selectGroupSize() {
                return [
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_1[this.lang] }`, value: null }, 
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_2[this.lang] }`, value: ['<', 10] },
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_3[this.lang] }`, value: ['<', 20] },
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_4[this.lang] }`, value: ['<', 30] },
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_5[this.lang] }`, value: ['>', 30] },
                ]
            },
            selectPrice() {
                return [
                    { label: `${ this.translates[this.langUrl].selectPrice_value_1[this.lang] }`, value: null },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_2[this.lang] }`, value: ['<', 50000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_3[this.lang] }`, value: [50000, 100000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_4[this.lang] }`, value: [100000, 250000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_5[this.lang] }`, value: [250000, 500000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_6[this.lang] }`, value: ['>', 500000] },
                ];
            },
            selectSorting() {
                return [
                    { label: `${ this.translates[this.langUrl].selectSorting_new[this.lang] }`, value: { createdAt: -1 } },
                    { label: `${ this.translates[this.langUrl].selectSorting_raiting[this.lang] }`, value: { viewCount: -1 } },
                ]
            },
        },
        watch: {
            defaultFilter(newVal, oldVal) {
                this.filter = {
                    ...this.filter,
                    ...newVal
                }
            }
        },
        methods: {
            updateFilterSearch(value) {
                this.filter.search = value;
                this.applyFilter();
            },
            updateFilterPlace(value) {
                this.filter.directions = value;
                this.applyFilter();
            },
            updateFilterDates(value) {
                if(value[0] > value[1]) {
                    this.filter.dates = [value[1], value[0]]
                } else {
                    this.filter.dates = value;
                }
                this.applyFilter();
            },
            updateFilterType(value) {
                this.filter.tripType = value;
                this.applyFilter();
            },
            updateFilterGroupSize(value) {
                this.filter.groupSize = value;
                this.applyFilter();
            },
            updateFilterGroupAge(value) {
                this.filter.groupAge = value;
                this.applyFilter();
            },
            updateFilterPrice(value) {
                this.filter.price = value;
                this.applyFilter();
            },
            updateSorting(value) {
                this.sorting = value;
                this.applyFilter();
            },
            applyFilter() {
                this.$emit('apply', { filter: this.filter, sorting: this.sorting });
            },
            clearFilters() {
                this.filter = {
                    search: '',
                    dates: [],
                    tripType: null,
                    directions: null,
                    groupAge: null,
                    groupSize: null,
                    price: null
                };
                this.$emit('apply', { filter: this.filter, sorting: this.sorting });
            }
        }
    }
</script>

<style lang="scss">
.view-trip-filter {
    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
    }

    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 159px;
        height: 40px;
        border: 1px solid #DA0A63;
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #DA0A63;
        cursor: pointer;

        &__icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        &__clear {
            width: 100%;
            margin-top: 18px;
        }
    }
    &__link{
        width: 100%;
        height: 30px;
        display: flex;

        &__clear {
            margin: 15px 0 0 auto;
        }
    }
    
    &__pagefilters {
        margin: 24px 20px;

        &__select-container {
            display: flex;
            width: 100%;
            height: 44px;
            margin-bottom: 12px;
            background: #FFFFFF;
        }

        &__select {
            // background: #FFFFFF;
            box-shadow: 0px 12px 20px rgba(30, 28, 46, 0.03);
            border-radius: 10px;
        }

        &__results {
            display: flex;
            flex-direction: row;
            margin-top: 24px;

            &__icon {
                margin-right: 6px;
            }

            &__text {
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #273155;
            }
        }
        &__sort {
            display: flex;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            margin-top: 20px;

            &__static {
                color: #273155;
            }

            &__select {
                color: #707378;
                margin-left: 8px;
            }
        }
    }
    &__pagefilter {
        display: flex;
        width: 100%;
        list-style-type: none;
        margin-bottom: 24px;
        flex-wrap: nowrap;
        overflow-x: scroll;

        @media screen {

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }

        &__button {
            margin-right: 8px;
            text-decoration: none;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            border: 1px solid #EDF1FA;
            padding: 6px 12px;
            height: 29px;
        }
    }	
}
</style>