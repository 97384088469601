<template>
    <v-select
        v-bind="$attrs"
        ref="select"
        color="#FF385C"
        class="custom-select"
        :value="value"
        append-icon="mdi-chevron-down"
        solo
        v-on="$listeners"
    >
        <template #prepend>
            <img
                v-if="type === 'map'"
                src="../../assets/MapPin.svg"
                class="prepend-icon" />
        </template>
        <template #append>
            <img src="../../assets/DownArrow.svg" class="custom-select__select-arrow" />
        </template>
    </v-select>
</template>

<script>

    export default {
        name: 'CustomSelect',
        inheritAttrs: false,
        props: {
            value: { type: [String, Array], default: '' },
            type: { type: String, default: '' }
        }
    }
</script>

<style lang="scss">
.custom-select {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        position: relative;
        margin-bottom: 12px !important;

    &__select-arrow {
        margin-right: 7px;
    }

    .v-input__slot {
        border-radius: 10px;
        background: #fff !important;
        box-shadow: none;
        box-shadow: rgba(30, 28, 46, 0.03);
        border: 1px solid #EBEBEB;
    }
    .v-text-field__details {
        display: none;
    }
    .v-input__icon--append > .v-icon {
        color: #ccc
    }
    .v-select__selection{
    // padding: 0 28px;
    color: #273155;
    &__without_icon {
        padding: 0;
    }
    }
    &.view-trip-list__pagefilters__select {
        box-shadow: none;
    }
    &.v-text-field.v-text-field--solo .v-input__control {
        min-height: 44px
    }
    &.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        margin-bottom: 0;
        box-shadow: none;
    }
    &.v-text-field--outlined .v-label {
        margin-left: 8px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        // @media all and (min-width: 768px) {
        //     display: none;
        // }
    }

    .v-input__prepend-outer {
        width: 0;
        // margin: 14px 0 0 0 !important;
    }

    .prepend-icon {
        position: absolute;
        left: 16px;
        top: 12px;
        z-index: 1;
    }
}
.v-messages {
        min-height: 0 !important;
    }
</style>
