<template>
    <div class="desktop-view-trip-filters" v-if="translates && translates[langUrl]">
        <!-- <div class="desktop-view-trip-filters__title">Все фильтры</div> -->
        <div class="desktop-view-trip-filters__pagefilters">
            <div class="desktop-view-trip-filters__pagefilters__container">
                <div class="desktop-view-trip-filters__pagefilter">
                    <FilterSelect
                        v-if="countries && cities"
                        :label="translates[langUrl].label_country[lang]"
                        :value="filter.directions"
                        :items="[{ code: null, title: `${ translates[langUrl].countries_notImportant[lang] }` }, ...countries]"
                        item-text="title"
                        item-value="code"
                        @change="updateFilterDirections"
                    />
                </div>
                <div class="desktop-view-trip-filters__pagefilter">
                    <RangeDatePicker
                        :value="filter.dates"
                        type="month"
                        :label="translates[langUrl].label_dates[lang]"
                        @change="updateFilterDates"
                    />
                </div>
                <div class="desktop-view-trip-filters__pagefilter">
                    <FilterSelect
                        :label="translates[langUrl].label_price[lang]"
                        :value="filter.price"
                        :items="selectPrice"
                        item-text="label"
                        item-value="value"
                        @change="updateFilterPrice"
                    />
                </div>
            </div>
            <!-- <div class="desktop-view-trip-filters__pagefilters__container">
                <div class="desktop-view-trip-filters__pagefilter">
                    <CustomTextField
                        :value="filter.search"
                        class="desktop-view-trip-filters__pagefilters__text-field"
                        @input="updateFilterSearch"
                    />
                </div>
                <div class="desktop-view-trip-filters__pagefilter">
                    <FilterSelect
                        :label="translates[langUrl].label_groupSize[lang]"
                        :value="filter.groupSize"
                        :items="selectGroupSize"
                        item-text="label"
                        item-value="value"
                        @change="updateFilterGroupSize"
                    />
                </div>
                <div class="desktop-view-trip-filters__pagefilter">
                    <BaseMultipleSelect
                        :label="translates[langUrl].label_tripType[lang]"
                        :value="filter.tripType"
                        :items="types"
                        item-text="title"
                        item-value="code"
                        multiple
                        persistent-hint
                        @change="updateFilterType"
                    />
                </div>
                <div class="desktop-view-trip-filters__pagefilters__button">Ещё фильтры</div>
            </div> -->
            <div class="desktop-view-trip-filters__pagefilters__container">
                <div class="desktop-view-trip-filters__pagefilters__buttons-container">
                    <div class="desktop-view-trip-filters__pagefilters__button desktop-view-trip-filters__pagefilters__button__clear" @click="clearFilters">{{ translates[langUrl].button_clearFilters[lang] }}</div>
                </div>
                <div class="desktop-view-trip-filters__pagefilters__sorting-container">
                    <div class="desktop-view-trip-filters__pagefilters__sorting">{{ translates[langUrl].label_sorting[lang] }}</div>
                    <SortingSelect
                        :value="sorting" 
                        :items="selectSorting"
                        item-text="label"
                        item-value="value"
                        @change="updateSorting"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import CustomTextField from '../../common/CustomTextField.vue';
    import FilterSelect from '../../common/FilterSelect.vue';
    import BaseMultipleSelect from '../../common/BaseMultipleSelect.vue';
    import BaseButton from '../../common/BaseButton.vue';
    import BaseCombobox from '../../common/BaseCombobox.vue';
    import CustomSelect from '../../common/CustomSelect.vue';
    import SortingSelect from '../../common/SortingSelect.vue';
    import RangeDatePicker from '../../common/RangeDatePicker.vue';

    export default {
        name: 'Filters',
        components: {
            CustomTextField,
            FilterSelect,
            BaseButton,
            BaseCombobox,
            CustomSelect,
            SortingSelect,
            RangeDatePicker,
            BaseMultipleSelect
        },
        props: {
            defaultFilter: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            filter: { 
                search: '',
                dates: [],
                tripType: [],
                directions: null,
                groupSize: null,
                price: null
            },
            sorting: { createdAt: -1 },
            selectGroupAge: [
                { label: 'не важно', value: null }, 
                { label: '18+', value: '18' },
                { label: '30+', value: '30' },
                { label: '40+', value: '40' },
                { label: '50+', value: '50' },
            ],
            langUrl: '/trips_filters'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('dictionaries', ['types', 'countries', 'cities']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            selectGroupSize() {
                return [
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_1[this.lang] }`, value: null }, 
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_2[this.lang] }`, value: ['<', 10] },
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_3[this.lang] }`, value: ['<', 20] },
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_4[this.lang] }`, value: ['<', 30] },
                    { label: `${ this.translates[this.langUrl].selectGroupSize_value_5[this.lang] }`, value: ['>', 30] },
                ]
            },
            selectPrice() {
                return [
                    { label: `${ this.translates[this.langUrl].selectPrice_value_1[this.lang] }`, value: null },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_2[this.lang] }`, value: ['<', 50000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_3[this.lang] }`, value: [50000, 100000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_4[this.lang] }`, value: [100000, 250000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_5[this.lang] }`, value: [250000, 500000] },
                    { label: `${ this.translates[this.langUrl].selectPrice_value_6[this.lang] }`, value: ['>', 500000] },
                ];
            },
            selectSorting() {
                return [
                    { label: `${ this.translates[this.langUrl].selectSorting_new[this.lang] }`, value: { createdAt: -1 } },
                    { label: `${ this.translates[this.langUrl].selectSorting_raiting[this.lang] }`, value: { viewCount: -1 } },
                ]
            },
        },
        watch: {
            defaultFilter(newVal, oldVal) {
                this.filter = {
                    ...this.filter,
                    ...newVal
                }
            }
        },
        methods: {
            updateFilterSearch(value) {
                this.filter.search = value;
                this.applyFilter();
            },
            updateFilterType(value) {
                this.filter.tripType = value;
                this.applyFilter();
            },
            updateFilterDirections(value) {
                this.filter.directions = value;
                this.applyFilter();
            },
            updateFilterPlace(value) {
                this.filter.place = value;
                this.applyFilter();
            },
            updateFilterGroupSize(value) {
                this.filter.groupSize = value;
                this.applyFilter();
            },
            updateFilterDates(value) {
                if(value[0] > value[1]) {
                    this.filter.dates = [value[1], value[0]]
                } else {
                    this.filter.dates = value;
                }
                this.applyFilter();
            },
            updateFilterPrice(value) {
                this.filter.price = value;
                this.applyFilter();
            },
            updateSorting(value) {
                this.sorting = value;
                this.applyFilter();
            },
            applyFilter() {
                this.$emit('apply', { filter: this.filter, sorting: this.sorting });
            },
            clearFilters() {
                this.filter = { 
                    search: '',
                    dates: [],
                    price: null,
                    directions: null, 
                    groupSize: null,
                    tripType: [], 
                };
                this.$emit('apply', { filter: this.filter, sorting: this.sorting });
            }
        }
    }
</script>

<style lang="scss">
.desktop-view-trip-filters {
    font-family: Inter;
    font-style: normal;

    &__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155;
        margin-bottom: 20px;
    }
    &__pagefilter {
        width: 30%;
    }
    &__pagefilters {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #F6F7F9;
        margin-bottom: 24px;
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .base-text-field {
                margin-bottom: 0 !important;
            }
            &:last-of-type {
                margin-bottom: 24px;
            }
        }
        &__text-field {
            max-height: 56px;
            margin-bottom: 0;
            &.base-text-field.v-text-field--outlined .v-label {
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #44414D;
            }
        }
        &__select {
            max-width: 228px;
            &.v-text-field.v-text-field--solo .v-input__control {
                min-height: 56px
            }
            .theme--light.v-label {
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #44414D;
            }
            &.custom-select {
                margin-bottom: 0 !important;
            }
        }
        &__buttons-container {
            display: flex;
        }
        &__button {
            display: flex;
            // justify-content: center;
            align-items: center;
            // padding: 10px;
            // margin-right: 8px;
            margin-left: 5px;
            // width: 136px;
            // height: 56px;
            // border: 1px solid #DA0A63;
            // box-sizing: border-box;
            // border-radius: 12px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #DA0A63;
            cursor: pointer;
            &:last-of-type {
                margin-right: 0;
            }
            // &__clear {
            //     width: 200px;
            //     height: 40px;
            // }
            // &__date {
            //     width: 82px;
            //     height: 40px;
            //     font-weight: normal;
            //     font-size: 14px;
            // }
            // &__place {
            //     width: 93px;
            //     height: 40px;
            //     font-weight: normal;
            //     font-size: 14px;
            // }
            // &__friends {
            //     width: 132px;
            //     height: 40px;
            //     font-weight: normal;
            //     font-size: 14px;
            // }
            // &__icon {
            //     width: 20px;
            //     height: 20px;
            //     margin-right: 8px;
            // }
        }
        &__sorting-container {
            display: flex;
            align-items: center;
        }
        &__sorting {
            color: #273155;
        }
    }
}
</style>